import "vite/modulepreload-polyfill";
import initialiseNav from "./components/navigation";

initialiseNav();

if (document.getElementById("alert-message")) {
  import("./components/alert").then((init) => init.default());
}

if (document.querySelector("[data-aos]")) {
  import("./components/aos").then((init) => init.default());
}

if (document.querySelector(".js-autocomplete")) {
  import("./components/autocomplete").then((init) => init.default());
}

if (document.querySelector(".js-lightbox")) {
  import("./components/lightbox").then((init) => init.default());
}

if (document.querySelector(".js-isotope-grid")) {
  import("./components/isotope").then((init) => init.default());
}

if (document.querySelector(".js-masonry-grid")) {
  import("./components/masonry").then((init) => init.default());
}

if (document.querySelector(".js-search-elsewhere")) {
  import("./components/search").then((init) => init.default());
}

if (document.querySelector("[data-map-type='single']")) {
  import("./components/singleMap").then((init) => init.default());
}
